<template>
        <transition name="modal">
                <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">

                    <div class="modal-header">
                           
                        <slot name="modal-header">
                        
                        </slot>
                            <button class="modal-default-button" @click="$emit('close')">
                           <i class="fa fa-close"></i>
                        </button>
                    </div>

                    <div class="modal-body">
                        <slot name="modal-body">
                
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                   
                       
                        </slot>
                    </div>
                    </div>
                </div>
                </div>
      </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    msg: String
  },
    data: function () {
    return {
       showModal: false,
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

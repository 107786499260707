
<template>
  <div class="app-content content home">
    <section id="base-style">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h1 class="mb-0 text-left">Oyun Listesi</h1>
             
              <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <li> <button type="button" class="btn mr-1 btn-warning open-mdl"  @click="_newGameDataModal()"><i class="fa fa-edit mr-1"></i> Yeni Oyun Ekle</button></li>
                  <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                  <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                  <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                  <li><a data-action="close"><i class="ft-x"></i></a></li>
                </ul>
              </div>
            </div>

           <!-- use the modal component, pass in the prop -->
                                  <modal v-if="showModal" @close="showModal = false">
                                      <template v-slot:modal-header>
                                        <h1 >{{gameDetailData.name}}</h1>
                                       
                                        <input type="hidden" v-model="actionType">
                                      </template>
                                       <template v-slot:modal-body>
                                        <div class="d-flex m-body" v-if="Object.keys(gameDetailData).length > 0">
                                          <div class="col-md-5">
                                             <div class="d-flex align-items-center h-60" >
                                              <h2 class="mr-2">Oyun Adı</h2>
                                              <input type="text" class="form-control" required v-model="gameDetailData.name"/>
                                            </div>
                                            <!-- <div class="d-flex align-items-center mt-3 justify-content-start h-60">
                                              <h2 class="mr-2">Durum</h2>
                                              <input type="checkbox" id="switcher" class="m-switch" /><label for="switcher" class="sw-label">Toggle</label>
                                            </div> -->
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Zamanlama</h2>
                                               <select class="form-control" id="basicSelect" v-model="gameDetailData.interval">
                                                <option></option>
                                                <option value="onetime">onetime</option>
                                                <option value="daily">daily</option>
                                                <option value="weekly">weekly</option>
                                                <option value="monthly">monthly</option>
                                            
                                              </select>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Saat</h2>
                                               <input type="number" class="form-control" required v-model.number="gameDetailData.time.hour">
                                                 <h2 class="ml-2 mr-2">Dakika</h2>
                                              <input type="text" class="form-control" required id="" v-model.number="gameDetailData.time.min">
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60" v-if="gameDetailData.interval == 'weekly'">
                                              <h2 class="mr-2">Haftanın Günü</h2>
                                              <input type="number" class="form-control" required id="" v-model.number="gameDetailData.time.dayInWeek">
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60"  v-if="gameDetailData.interval == 'onetime'">
                                              <h2 class="mr-2">Hangi Ay</h2>
                                              <input type="number" required class="form-control" id="" v-model.number="gameDetailData.time.month">
                                              <h2 class="mr-2">Ayın Günü</h2>
                                              <input type="number" required class="form-control" id="" v-model.number="gameDetailData.time.day">
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60" v-if="gameDetailData.interval == 'monthly'">
                                               <h2 class="mr-2">Ayın Günü</h2> 
                                              <input type="number" required class="form-control" id="" v-model.number="gameDetailData.time.month">
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-70">
                                              <h2 class="mr-2">Başlangıç Tarihi</h2>
                                     
                                            <datetime v-model="gameDetailData.time.dateRangeStart"></datetime>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-70">
                                              <h2 class="mr-2">Bitiş Tarihi</h2>
                                             
                                              <datetime v-model="gameDetailData.time.dateRangeEnd"></datetime>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60" >
                                              <h2 class="mr-2">Bekleme Süresi</h2>
                                              <input type="number" required class="form-control" v-model.number="gameDetailData.properties.lobbyDuration"/>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Tur Arası</h2>
                                              <input type="number" required class="form-control" v-model.number="gameDetailData.properties.intermissionDuration"/>
                                            </div>
                                      
                                           
                                          </div>
                                          <div class="col-md-7">
                                            <div class="d-flex align-items-center h-60">
                                              <h2 class="mr-2">Pass Süresi</h2>
                                              <input type="number" required class="form-control" v-model.number="gameDetailData.properties.passAllowedDuration"/>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Reklam Olasılığı</h2>
                                              <input type="number" required class="form-control" v-model.number="gameDetailData.properties.adOfferPercentage"/>
                                            </div>
                                             <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Etkileşim Süresi</h2>
                                              <input type="number" required class="form-control" v-model.number="gameDetailData.properties.adDuration"/>
                                            </div>
                                               <!-- <div class="d-flex align-items-center mt-3 h-60">
                                                <h2 class="mr-2">Pass Çarpanı</h2>
                                                <input type="text" class="form-control" v-model.number="gameDetailData.properties.roundPassMultiplier"/>
                                              </div> -->
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Akçe Kazanımı</h2>
                                              <div class="d-flex flex-column">
                                                <span>Baz</span>
                                                <input type="number" required class="form-control" v-model.number="gameDetailData.properties.roundReward"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Tur Artışı</span>
                                                <input type="number" required class="form-control" v-model.number="gameDetailData.roundRewardIncrement"/>
                                              </div>
                                            
                                              <!-- <div class="d-flex flex-column ml-1">
                                                <span>Faz 1</span>
                                                <input type="text" class="form-control" v-model="gameDetailData.roundRewardPeriodMultiplier[1]"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Faz 2</span>
                                                <input type="text" class="form-control" v-model="gameDetailData.roundRewardPeriodMultiplier[2]"/>
                                              </div> -->
                                              <div class="d-flex flex-column ml-1 " v-for="(items,index) in (gameDetailData.properties.roundRewardPeriodMultiplier)" :key="index">
                                                <span>Faz {{index}} </span>
                                                <input type="number" required class="form-control" v-model="gameDetailData.properties.roundRewardPeriodMultiplier[index]"/>
                        
                                              </div>
                                            </div>
                                            
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Değişim Ücreti</h2>
                                               <div class="d-flex flex-column">
                                                <span>Baz</span>
                                                <input type="number" required class="form-control" v-model.number="gameDetailData.properties.prices.change.base"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Tur Artışı</span>
                                                <input type="number" required class="form-control" v-model.number="gameDetailData.properties.prices.change.increment"/>
                                              </div>
                                            </div>
                                             <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Grafik Jokeri</h2>
                                               <div class="d-flex flex-column">
                                                <span>Baz</span>
                                                <input type="number" required class="form-control" v-model.number="gameDetailData.properties.prices.graph.base"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Tur Artışı</span>
                                                <input type="number" required class="form-control" v-model.number="gameDetailData.properties.prices.graph.increment"/>
                                              </div>
                                            </div>
                                              <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Geçiş Anahtarı</h2>
                                               <div class="d-flex flex-column">
                                                <span>Baz</span>
                                                <input type="number" required class="form-control" v-model.number="gameDetailData.properties.prices.pass.base"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Pass Çarpanı</span>
                                                <input type="number" required class="form-control" v-model.number="gameDetailData.properties.prices.pass.increment"/>
                                              </div>
                                            </div>
                                              <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">No Joker Player Count</h2>
                                              <input type="number" required class="form-control" v-model.number="gameDetailData.properties.noJokersAllowedPlayerCount"/>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="w-100 text-left mt-3">
                                          <h1>Ödüller</h1>
                                        </div>
                                          <div class="col-md-12 position-relative mt-5">
                                               <vsa-list class="w-100">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                    Yeni Ekle
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                          <!-- <div class="d-flex align-items-center">
                                                            <h2 class="mr-2 col-md-6 text-left">Oyun Adı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Kalan Oyuncu</h2>
                                                            <input type="text" class="form-control" v-model.number="newRank"/>
                                                          </div>

                                                           <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Coin Ödülü</h2>
                                                              <input type="text" class="form-control" v-model.number="newCoin"/>
                                                            </div>
                                                 
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Pass</h2>
                                                            <input type="text" class="form-control" v-model.number="newPass"/>
                                                          </div>
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                            <button type="button" class="btn mr-1 mb-1 btn-success" @click="_addNewPrize()"><i class="fa fa-edit mr-1"></i> Ödülü Ekle</button>
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>




                                            <vsa-list class="w-100">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                   Winner
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                          <!-- <div class="d-flex align-items-center">
                                                            <h2 class="mr-2 col-md-6 text-left">Oyun Adı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Kalan Oyuncu</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                            <!-- <div class="d-flex align-items-center mt-2">
                                                                <h2 class="mr-2 col-md-6 text-left">Durum</h2>
                                                                <input type="checkbox" id="switcher" class="m-switch" /><label for="switcher" class="sw-label">Toggle</label>
                                                              </div> -->
                                                               <div class="d-flex align-items-center justify-content-between h-60" >
                                                                    <h2 class="mr-2 col-md-6 text-left">Ödül Tipi</h2>
                                                                  
                                                                    <div class="d-flex">
                                                                      <label class="radio">
                                                                        <input type="radio" name="radAnswer" @click="_setPriceType()" id="three" value="true" checked>
                                                                        <span>Özel Ödül</span>
                                                                      </label>
                                                                      <label class="radio">
                                                                          <input type="radio" name="radAnswer" id="four" @click="_setPriceType()" value="false" >
                                                                          <span>Para Ödülü</span>
                                                                      </label>
                                                                  </div>
                                                               </div>
                                                           <div class="d-none align-items-center mt-2 cashprize">
                                                              <h2 class="mr-2 col-md-6 text-left">Para Ödülü</h2>
                                                              <input type="text" class="form-control" v-model.number="gameDetailData.properties.prizes.winner.cash"/>
                                                            </div>
                                                            <div class=" d-flex align-items-center mt-2 specialprize" >
                                                              <h2 class="mr-2 col-md-6 text-left">Özel Oyun Adı</h2>
                                                              <input type="text" class="form-control" v-model="gameDetailData.properties.specialPrize"/>
                                                            </div>
                
                                                           
                                                             <div class="d-flex align-items-center mt-2 specialprize">
                                                              <h2 class="mr-2 col-md-6 text-left">Özel Oyun Resmi (PNG formatında 480x560 olmalıdır)</h2>
                                                               <input type="hidden" class="form-control" v-model="gameDetailData.properties.specialImage"/>
                                                              <!-- <input type="file" @change="_uploadImage" name="image" id="image"  accept="image/*" > -->
                                                                    <input
                                                                          accept="image/x-png"
                                                                          type="file"
                                                                          name="file-input"
                                                                          id="file-input"
                                                                          class="file-input__input"
                                                                          @change="_uploadImage"
                                                                        />
                                                              <div>
                                                                        <label class="file-input__label" for="file-input">
                                                                          <svg
                                                                            aria-hidden="true"
                                                                            focusable="false"
                                                                            data-prefix="fas"
                                                                            data-icon="upload"
                                                                            class="svg-inline--fa fa-upload fa-w-16"
                                                                            role="img"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512"
                                                                          >
                                                                          
                                                                            <path
                                                                              fill="currentColor"
                                                                              d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                                                                            ></path>
                                                                          </svg>
                                                                          <span>Resim Seç</span></label
                                                                        >
                                                                        <div class="load mt-3" v-bind:class="imageLoadStatus ? 'Replace' : 'Click to upload'">
                                                                          <svg class="loader" version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                                            <path fill="#4468d6" d="M256.011 0c-12.852 0-23.273 10.42-23.273 23.273v93.091c0 12.854 10.421 23.274 23.273 23.274 12.853 0 23.272-10.421 23.272-23.274v-93.091c0-12.853-10.419-23.273-23.272-23.273z"></path>
                                                                            <path fill="#4468d6" opacity="0.4" d="M256.011 372.363c-12.852 0-23.273 10.421-23.273 23.272v93.091c0 12.853 10.421 23.274 23.273 23.274 12.853 0 23.272-10.421 23.272-23.274v-93.091c0-12.853-10.419-23.272-23.272-23.272z"></path>
                                                                            <path fill="#4468d6" opacity="0.8" d="M173.725 140.809l-65.826-65.828c-9.086-9.089-23.822-9.089-32.912 0-9.089 9.089-9.089 23.824 0 32.912l65.826 65.828c4.544 4.544 10.5 6.816 16.455 6.816s11.912-2.273 16.455-6.816c9.090-9.089 9.090-23.823 0.001-32.912z"></path>
                                                                            <path fill="#4468d6" opacity="0.1" d="M459.806 232.727h-46.546c-12.853 0-23.272 10.421-23.272 23.273 0 12.853 10.419 23.272 23.272 23.272h46.546c12.853 0 23.272-10.419 23.272-23.273 0-12.852-10.421-23.273-23.272-23.273z"></path>
                                                                            <path fill="#4468d6" opacity="0.3" d="M365.557 338.281c-9.087-9.089-23.823-9.087-32.913 0-9.088 9.089-9.087 23.823 0 32.913l65.828 65.825c4.544 4.544 10.502 6.817 16.457 6.817 5.957 0 11.913-2.274 16.455-6.817 9.089-9.089 9.089-23.825 0-32.913l-65.828-65.825z"></path>
                                                                            <path fill="#4468d6" opacity="0.6" d="M139.637 256c0-12.852-10.421-23.273-23.273-23.273h-93.091c-12.853 0-23.273 10.421-23.273 23.273 0 12.853 10.42 23.272 23.273 23.272h93.091c12.852 0 23.273-10.419 23.273-23.273z"></path>
                                                                            <path fill="#4468d6" opacity="0.5" d="M173.735 338.283c-9.087-9.089-23.825-9.089-32.912 0l-65.825 65.825c-9.089 9.087-9.089 23.825 0 32.913 4.544 4.544 10.501 6.815 16.457 6.815s11.913-2.271 16.455-6.815l65.825-65.825c9.089-9.087 9.089-23.822 0-32.911z"></path>
                                                                          </svg>
                                                                      </div>
                                                                       </div>
                                                                         <img id="output" class="special-image ml-3" v-bind:class="{ loaded: gameDetailData.properties.specialImage }" :src="imgPath">
                                                                      </div>
                                                   
                                                                      
                                                                    </div>
                                                            <div class="file-input">

                                                         
                                                        
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                               <vsa-list class="w-100">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                   Runnerups
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                          <!-- <div class="d-flex align-items-center">
                                                            <h2 class="mr-2 col-md-6 text-left">Oyun Adı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Pass</h2>
                                                            <input type="text" class="form-control" v-model.number="gameDetailData.properties.prizes.runnerups.pass"/>
                                                          </div>
                                                            <!-- <div class="d-flex align-items-center mt-2">
                                                                <h2 class="mr-2 col-md-6 text-left">Durum</h2>
                                                                <input type="checkbox" id="switcher" class="m-switch" /><label for="switcher" class="sw-label">Toggle</label>
                                                              </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Coin Ödülü</h2>
                                                              <input type="text" class="form-control" v-model.number="gameDetailData.properties.prizes.runnerups.coin"/>
                                                            </div>
                                                    
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                      
                                                          
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                              <vsa-list class="w-100" v-for="(item,index) in gameDetailData.properties.prizes.qualifying" :key="index">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                   Son{{item.rank}}
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                          <!-- <div class="d-flex align-items-center">
                                                            <h2 class="mr-2 col-md-6 text-left">Oyun Adı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Kalan Oyuncu</h2>
                                                            <input type="text" class="form-control" v-model.number="item.rank"/>
                                                          </div>
                                                            <!-- <div class="d-flex align-items-center mt-2">
                                                                <h2 class="mr-2 col-md-6 text-left">Durum</h2>
                                                                <input type="checkbox" id="switcher" class="m-switch" /><label for="switcher" class="sw-label">Toggle</label>
                                                              </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Coin Ödülü</h2>
                                                              <input type="text" class="form-control" v-model.number="item.prize.coin"/>
                                                            </div>
                                                            <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Pass</h2>
                                                              <input type="text" class="form-control" v-model.number="item.prize.pass"/>
                                                            </div>
                                                           <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Özel Ödül İmajı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Akçe</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Geçiş Anahtarı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                       
                                                            <button type="button" class="btn mr-1 mb-1 btn-danger" v-on:click="_removeElement(item)"><i class="fa fa-trash mr-1"></i> Ödülü Sil</button>
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                          </div>
                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                            <button  v-if="actionType == 'update'" type="button" class="btn-icon btn mr-1 mb-1 btn-success" v-bind:id="gameDetailData._id"  @click="_updateGame($event)"><i class="fa fa-edit mr-1"></i> Oyunu Güncelle</button>
                                                            <button  v-if="actionType == 'add'" type="button" class="btn-icon btn mr-1 mb-1 btn-success"  @click="_createGame($event)"><i class="fa fa-edit mr-1"></i> Oyun Ekle</button>
                                                            <button v-if="actionType == 'update'" type="button" class="btn mb-1 btn-danger delete" v-bind:id="gameDetailData._id"  @click="_deleteGame($event)"><i class="fa fa-trash mr-1"></i> Oyunu Sil</button>
                                                          </div>
                                      </template>
                                  </modal>
                                  <!-- MODAL END -->
				<div class="card-content collapse show">
					<div class="card-body card-dashboard">{{this.rows._id}}


                   <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :search-options="{
                        enabled: true
                      }"
                      :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50],
                        dropdownAllowAll: false,
                        setCurrentPage: 2,
                        nextLabel: 'next',
                        prevLabel: 'prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                      <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'before'" style="display:flex;max-width:300px">
                            <button type="button" class="btn mr-1 btn-warning open-mdl" v-bind:id="props.row._id" @click="_getGameDetail($event);"><i class="fa fa-edit mr-1"></i> Düzenle</button>
                            <button type="button" class="btn  btn-danger delete" v-bind:id="props.row._id" @click="_deleteGame($event)"><i class="fa fa-trash mr-1"></i> Oyunu Sil</button>
                           
                          </span>
                          <span v-else-if="props.column.field == 'after'">
                            after
                          </span>
                          <span v-else>
                            {{props.formattedRow[props.column.field]}}
                          </span>
                      </template>
                    </vue-good-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
   
  </div>

</template>


<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
window.$ = JQuery;
import Swal from 'sweetalert2';
import 'vue-datetime/dist/vue-datetime.css';
export default {
  name: "Home",
  components: {
    Modal,
  },
  data(){
    return {
      columns: [
        {
          label: 'Oyun Adı',
          field: 'name',
        },
         {
          label: 'Oyun ID',
          field: '_id',
        },
        {
          label: 'Oyun Tipi',
          field: 'type',
          type: 'text',
        },
        {
          label: 'İşlem',
          field: 'before',
          width: '200px',
        }
      ],
       gameDetailData: 
                          {
                name: '',
                type: 'maingame',
                interval: '',
                time: {
                  hour: 0,
                  min: 0,
                  month: undefined,
                  day : undefined,
                  dayInWeek: undefined,
                  dateRangeStart:undefined,
                  dateRangeEnd: undefined,
                },
                properties:{
                  adDuration: 0,
                adOfferPercentage: 0.5,
                intermissionDuration: 30,
                lobbyDuration: 300,
                noJokersAllowedPlayerCount: 10,
                passAllowedDuration: 0,
                prices: {
                  change: {
                    base: 0,
                    increment: 0
                  },
                  graph: {
                    base: 0,
                    increment: 0
                  },
                  pass: {
                    base: 0,
                    increment: 0
                  }
                },
                prizes: {
                  winner: {
                    cash: 0
                  },
                  runnerups: {
                    coin: 0,
                    pass: 0
                  },
                  qualifying: [],
                
                },
                specialImage: '',
                specialPrize: '',
                roundDuration: 30,
                roundReward: 25,
                roundRewardIncrement: 10,
                roundRewardPeriodMultiplier: { 1:1,  2:2},
          
                }
},
             newGameDetailData: 
                          {
                name: '',
                type: 'maingame',
                interval: '',
                time: {
                  hour: 0,
                  min: 0,
                  month: undefined,
                  day : undefined,
                  dayInWeek: undefined,
                  dateRangeStart:undefined,
                  dateRangeEnd: undefined,
                },
                properties:{
                adDuration: 0,
                adOfferPercentage: 0.5,
                intermissionDuration: 30,
                lobbyDuration: 300,
                noJokersAllowedPlayerCount: 10,
                passAllowedDuration: 0,
                prices: {
                  change: {
                    base: 0,
                    increment: 0
                  },
                  graph: {
                    base: 0,
                    increment: 0
                  },
                  pass: {
                    base: 0,
                    increment: 0
                  }
                },
                prizes: {
                  winner: {
                    cash: 0
                  },
                  runnerups: {
                    coin: 0,
                    pass: 0
                  },
                  qualifying: [],
                },
                specialImage: '',
                specialPrize: '',
                roundDuration: 30,
                roundReward: 25,
                roundRewardIncrement: 10,
                roundRewardPeriodMultiplier: { 1:1,  2:2},
                
                }
},
      showModal:false,
      rows: [],
      targetId: '',
      newData: [],
      removeId: '',
      updateId: '',
      newRank: '0',
      newCoin: '0',
      newPass: '0',
      actionType: '',
      imagePath: '',
      imageURL: '',
      imageLoadStatus: true,
      imgPath:'',
      imageUpload: false,
      priceType: true,
    }
  },
  watch: {
    clinicData () {
        this.clinic = this.clinicData
    }
  },
  methods: {
          _getListData(){
            axios.get( this.$hostname +'/games', {params:{
            type: 'maingame',
            }})
              .then((response) => {
                this.rows = response.data;
                console.log(this.rows);
            });
          },
          _getGameDetail(event){
            this.actionType = "update";
     
            // this.gameDetailData = ;
            this.targetId = event.currentTarget.id;
            console.log(this.targetId); 

             axios.get( this.$hostname +'/games', {params:{
            id: this.targetId,
            }})
              .then((response) => {
                
                this.gameDetailData = response.data;
                this.imgPath = 'http://'+this.gameDetailData.properties.specialImage;
                console.log(this.gameDetailData);
                this.gameDetailData.time.month = this.gameDetailData.time.month + 1;
                if(this.gameDetailData.time.hour != undefined){
                var d = new Date();
                d.setUTCHours(this.gameDetailData.time.hour);
                this.gameDetailData.time.hour = d.getHours();
                }
                this.showModal = true;
                 setTimeout(() => {
                if(this.gameDetailData.properties.prizes.winner.cash > 0){
                    $("#four").attr('checked', 'checked');
                            $(".specialprize").addClass("d-none");
                            $(".specialprize").removeClass("d-flex");
                            $(".cashprize").addClass("d-flex");
                            $(".cashprize").removeClass("d-none");
                    console.log("bigg");
                }else{
                  console.log("kkkkks");
                  
                           $("#three").attr('checked', 'checked');
                }
                  }, 500);
            });
          },
          _deleteGame(event){
            this.removeId = event.currentTarget.id;
                Swal.fire({
                title: 'Silmek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sil!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                  //this.updateId = event.currentTarget.id;
                    console.log("remove id", this.removeId);
                  axios.delete(this.$hostname +'/games', {params:{
                  id: this.removeId,
                }})
                .then(
                  () =>
                  this._getListData(),
                  this.showModal = false
                )
                  Swal.fire(
                    'Silindi!',
                    'Oyun başarıyla silindi !',
                    'success'
                  )
                }
              })
              
           
          },
          _addNewPrize(){
              this.gameDetailData.properties.prizes.qualifying.push({rank: this.newRank, prize: {coin: this.newCoin, pass: this.newPass}});
            //  this.gameDetailData.prizes.qualifying.prize.push({coin: this.newCoin , pass: this.newPass });
          },
          _removeElement(index) {
              this.gameDetailData.properties.prizes.qualifying.splice(index, 1);
            },
          _updateGame(event){
              this.updateId = event.currentTarget.id;
              console.log("update id", this.updateId);
              console.log(this.gameDetailData);
                    Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
         
                  if(this.gameDetailData.time.hour != undefined){
                    var d = new Date();
                    d.setHours(this.gameDetailData.time.hour);
                    this.gameDetailData.time.hour = d.getUTCHours();
                  }
                   this.gameDetailData.time.month = this.gameDetailData.time.month - 1;
                  //this.updateId = event.currentTarget.id;
                  axios.put(this.$hostname +'/games', this.gameDetailData, {headers: { 'Content-Type': 'application/json'}})
                  .then(() =>
                  this._getListData(),
                  this.showModal = false,
                ).catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }

                  })
                  Swal.fire(
                    'Güncellendi!',
                    'Oyun başarıyla Güncellendi !',
                    'success'
                  )
                }
              })
           
          },
          _newGameDataModal(){
           this.gameDetailData = {...this.newGameDetailData};
            this.showModal = true;
            this.actionType = "add";
            console.log("show modal");
            console.log("show modal", this.gameDetailData);
          },
          _createGame(){
      
               Swal.fire({
                title: 'Eklemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ekle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                   this.gameDetailData.time.month = this.gameDetailData.time.month - 1;
                    if(this.gameDetailData.time.hour != undefined){
                    var d = new Date();
                    d.setHours(this.gameDetailData.time.hour);
                    this.gameDetailData.time.hour = d.getUTCHours();
                  }
                  //this.updateId = event.currentTarget.id;
                  console.log("update id", this.updateId);
                  console.log(this.modalDetailData);
                  axios.post(this.$hostname +'/games', this.gameDetailData, {headers: { 'Content-Type': 'application/json'}})
                .then(
                  () =>
                    this._getListData(),
                  this.showModal = false
                ).catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }

                  })
                  Swal.fire(
                    'Kayıt Eklendi!',
                    'İşlem başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          },
          _uploadImage (e) {
            let img = e.target.files[0]
            let fd= new FormData()
            this.imageLoadStatus = false;
            console.log("img", img);
             console.log("path data", this.imageLoadStatus);
             $('.special-image').attr('src', img);
             document.getElementById('output').src = window.URL.createObjectURL(img)
             $(".load").addClass("loaded");
             $(".special-image").addClass("loaded");
            fd.append('file', img)
             if (img.size > 480 * 560) {
                  e.preventDefault();
                  alert('480x560 ölçülerinde png harici kabul edilmez.)');
                  return;
              }else{
                      axios.post(this.$hostname +'/upload', fd,)
                        .then(resp => {
                         this.gameDetailData.properties.specialImage = resp.data;
                         this.imgPath = 'http://'+this.gameDetailData.properties.specialImage;
                          console.log("path", this.gameDetailData.properties.specialImage);
                          this.imageLoadStatus = true;
                          console.log("path data", this.imageLoadStatus);
                               $(".load").removeClass("loaded");
                 
                        })
              }
          },
          _setPriceType(){ 
           let val = $('input[name="radAnswer"]:checked').val();
           console.log(val);
           if(val == 'true'){
             $(".specialprize").addClass("d-flex");
             $(".specialprize").removeClass("d-none");
              $(".cashprize").addClass("d-none");
              $(".cashprize").removeClass("d-flex");
           }else if (val == 'false'){
              $(".specialprize").addClass("d-none");
              $(".specialprize").removeClass("d-flex");
              $(".cashprize").addClass("d-flex");
               $(".cashprize").removeClass("d-none");
           }

          }
        

  },
  mounted(){
    this._getListData();
  },
  computed:{
    sortedData (){

    },
  }

};
</script>
